import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react'
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import DatePicker from "react-datepicker";
import { createGinApi, createItemApi, createPurchaseOrderApi, createVendorApi, deleteItemByIdApi, fileUploadApi, getGinByIdApi, getInventoryApi, getItemsApi, getPurchaseOrderById, getPurchaseOrderByIdApi, getvendorsApi, submitAndConvertToPurchaseOrderApi, termsAndConditionsApi, updateGinApi, updateItemByIdApi, updatePurchaseOrderApi, updateVendorByIdApi } from '../../utils/ApiEndpointFunctions';
import ItemsAddModal from '../../components/modal/ItemsAddModal';
import { useFormik } from 'formik';
import { addItemInitialValue, addItemValidationSchema, ginAddInitalValue, ginAddValidationSchema, poAddInitalValue, poAddValidationSchema, vendorAddInitailValue, vendorAddValidationSchema } from '../../utils/Validation';
import Spinwheel from '../../components/common/Spinwheel';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import VendorAddModal from "../../components/modal/VenderAddModal";
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import { formatPrice,getRoundOff } from '../../utils/HelperFuncitons';
import { useUserContext } from '../../context/userContext';
import moment from 'moment';
import StatusModal from '../../components/modal/StatusModal';
import Select from 'react-select';
import { S3_URL } from '../../utils/BaseUrl';
import Popup from "reactjs-popup";
import SignaturePad from "react-signature-canvas";

function AddGIN() {
  const [addItemLoader, setAddItemLoader] = useState(false)
  const [addLoader, setAddLoader] = useState(false)
  const sigCanvas = useRef({});
  const [submitClick, setSubmitClick] = useState(false);
  const [convertLoader, setConvertLoader] = useState(false)
  const addItemModalId = 'addItemModalId';
  const addVendorModalId = 'addVendorModalId';
  const updateItemModalId = "UpdateItemModalId";
  const ItemDeleteModalId = 'itemDeleteModalId';
  const submitConfirmModal = "submitConfirmModal1"
  const statusModalId = "statusModal1"
  const convertConfirmModal = "convertConfirmModal1"
  const convertStatusModalId = "covertStatusModal1"
  const [PoEditId,setPoEditId] = useState(null)
  const navigate = useNavigate();
  const params = useParams();
  const { ginId } = params;
  const [selectItemId, setSelectItemId] = useState();
  const [ginDetails, setGinDetails] = useState({})
  const { userDetails } = useUserContext();
  const [taxItems, setTaxItems] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [confirmationMsg, setConfirmationMsg] = useState('');
  const [terms, setTerms] = useState()
  

  //items =================================================================

  const handleItemDelete = async () => {
    try {
      const res = await deleteItemByIdApi(selectItemId)
      if (res.status === 200) {
        toast.success(res.data?.message)
        document.getElementById(ItemDeleteModalId).click()
        let items = ginFormik?.values?.items?.filter(ele => { return (ele.itemId !== selectItemId) })
        ginFormik.setFieldValue("items", items)
      }
    } catch (error) {
      console.log(error)
      toast.error("something went wrong");
    }
  }

  const filteritems = async (inputValue, cb) => {
    try {
      const res = await getItemsApi(inputValue, "", "", ginFormik.values.vendorId)
      if (res.status === 200) {
        let fetchedItem = res.data?.data?.items?.map(ele => ({ label: ele.name, value: ele }))
        cb(fetchedItem);
      }
    } catch (error) {
      console.log(error)
    }
  };
  const itemsOnChange = debounce(filteritems, 1000);

  const itemsOptions = (inputValue, callback) => {
    itemsOnChange(inputValue, callback);
  }

  const itemSelectHandler = (e) => {

    const item = {
      selected: true,
      itemId: e._id,
      quantity: '',
      unitPrice: '',
      unitType:e?.unitType || "",
      otherUnit:"",
      itemDetails: e
    }
    ginFormik.setFieldValue("items", [...ginFormik.values.items, item])
  }

  const itemsSubmit = async (values) => {
    try {
      setAddItemLoader(true)
      if(values?.unitType?.toLowerCase()!="other"){
        values.otherUnit = ""
      }
      const res = selectItemId ? await updateItemByIdApi({ ...values, vendorId: ginFormik.values.vendorId, unitPrice: 0, totalQuantity: 0 }) : await createItemApi({ ...values, vendorId: ginFormik.values.vendorId, unitPrice: 0, totalQuantity:0 })
      if (res.status === 200) {
        toast.success(res.data.message)

        // if (values._id && ginFormik && ginFormik.values && ginFormik.values.items) {
        //   ginFormik.values.items = ginFormik?.values?.items?.filter(ele => { return (ele.itemId !== values._id) })
        // }
        if(selectItemId && ginFormik?.values?.items){
          const editItemArr = ginFormik?.values?.items?.map(item=>{
            
              if(item?.itemId==res.data?.data?._id){
              return { 
                ...item,
                unitType:item?.unitType?.toLowerCase()=="other" ? item?.otherUnit : item?.unitType,
                otherUnit:"",
                selected: true,
                itemDetails: res.data?.data}
              }else{
                return item
              }
          })
          // ginFormik.values.items = ginFormik?.values?.items?.filter(ele => { return (ele.itemId !== values._id) })
          ginFormik.setFieldValue("items",editItemArr)
        }else{
          itemSelectHandler(res.data?.data)
        }
        setSelectItemId('')
        document.getElementById(addItemModalId).click()
      }
    } catch (error) {
      error && error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("something went wrong");
    }
    setAddItemLoader(false)
  }

  const removeItem = async (itemId) => {

    ginFormik.setFieldValue("items", [...ginFormik?.values?.items?.filter(ele => { return (ele.itemId !== itemId) })])
    document.getElementById(addItemModalId).click()
    try {
      const res = await deleteItemByIdApi( itemId, ginFormik.values.vendorId)
      if (res.status === 200) {
        toast.success(res.data?.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const itemFormik = useFormik({
    initialValues: addItemInitialValue,
    onSubmit: itemsSubmit,
    validationSchema: addItemValidationSchema
  })

  //vendor =================================================================

  const handleSelectDate = (e, eleType) => {
    if (eleType === 'dueDate') {
      ginFormik.setFieldValue("dueDate", new Date(e).toISOString())
    } else {
      ginFormik.setFieldValue("createDate", new Date(e).toISOString())
    }
  };

  const handleVendorSelect = (e) => {
    if (e.__isNew__) {
      VendorFormik.resetForm();
      VendorFormik.setFieldValue("name", e.value);
      ginFormik.resetForm();
      return document.getElementById("vendorCreate").click()
    } else {
      ginFormik.setFieldValue("vendorDetails", e.value)
      ginFormik.setFieldValue("vendorId", e.value?._id)
    }
  }

  const editVendorHandler = (vendorDetails) => {
    document.getElementById("vendorCreate").click()

    let vendorValue = {
      bankAccountNo: vendorDetails?.bankAccountNo,
      bankName: vendorDetails?.bankName,
      IFSC: vendorDetails?.IFSC,
      email: vendorDetails?.email,
      gstNumber: vendorDetails?.gstNumber,
      panNumber: vendorDetails?.panNumber,
      isActive: vendorDetails?.isActive,
      name: vendorDetails?.name,
      phone: vendorDetails?.phone,
      siteId: vendorDetails?.siteId,
      _id: vendorDetails?._id,
      address1: vendorDetails?.vendorAddressId?.address1,
      address2: vendorDetails?.vendorAddressId?.address2,
      city: vendorDetails?.vendorAddressId?.city,
      createdAt: vendorDetails?.vendorAddressId?.createdAt,
      isActive: vendorDetails?.vendorAddressId?.isActive,
      pinCode: vendorDetails?.vendorAddressId?.pinCode,
      state: vendorDetails?.vendorAddressId?.state,
      type: vendorDetails?.type,
      otherCity:""
    }
    VendorFormik.setValues(vendorValue)
    VendorFormik.setFieldValue("siteId", undefined);

  }



  const filtervendors = async (inputValue, cb) => {
    try {
      const type = "Sub-Contractor"
      const res = await getvendorsApi(inputValue,"","",type)
      if (res.status === 200) {
        let fetchedItem = res.data?.data?.vendors?.map(ele => ({ label: ele.name, value: ele }))
        cb(fetchedItem);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const vendorOnChange = debounce(filtervendors, 1000);

  const vendorOptions = (inputValue, callback) => {
    vendorOnChange(inputValue, callback);
  }


  const vendorSubmit = async (values) => {
    try {
      setAddItemLoader(true);
      if(values.city.toLowerCase()!="other"){
        values.otherCity = ""
      }
      const res = values?._id ? await updateVendorByIdApi({ ...values, siteId: userDetails?.siteId?._id }) : await createVendorApi({ ...values, siteId: userDetails?.siteId?._id });
      if (res.status === 200) {
        toast.success(res.data.message)
        const vendor = res.data?.data

        ginFormik.setFieldValue("vendorDetails", vendor)
        ginFormik.setFieldValue("type", vendor?.type)
        ginFormik.setFieldValue("vendorId", vendor?._id)
        document.getElementById(addVendorModalId).click()
      }
    } catch (error) {
      error && error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("something went wrong");
    }
    setAddItemLoader(false);
  }

  const VendorFormik = useFormik({
    initialValues: vendorAddInitailValue,
    onSubmit: vendorSubmit,
    validationSchema: vendorAddValidationSchema
  })


  // purchase orders =================================

  const checkHandler = (e, ele) => {
    let items = ginFormik.values.items.map((item, i) => {
      if (item.itemId === ele.itemId) {
        return {
          ...item,
          selected: e.target.checked
        }
      } else {
        return item;
      }
    });

    ginFormik.setFieldValue("items", items)
  }

  const poSubmit = async (values) => {

    let payload = JSON.parse(JSON.stringify(values));
    payload.items = payload.items.filter(item => item.selected)

    if (payload.items.length === 0) {
      return toast.error("Atleast select one item")
    }
    console.log("poSubmit",payload);

    // delete payload.convertToPurchase;
    // let amt = totalAmount(ginFormik?.values?.items?.filter(item => item?.selected))

    // payload.amount = Math.round(amt)
    // payload.roundOffAmount = getRoundOff(amt)


  setAddLoader(true);
    try {

      const res = values?._id ? await updateGinApi(payload) : await createGinApi(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        document.getElementById(submitConfirmModal).click()
        setConfirmationMsg(`GIN ${res.data?.data?.ginNumber || ""} is successfully ${ginDetails?.status === "submitted"? "updated.": "submitted."}`)
        document.getElementById('statusModalBtn').click()
       
      }
    } catch (error) {
      error && error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("something went wrong");
    }

    if (values.convertToPurchase) setConvertLoader(false)
    else setAddLoader(false)
  }

  const ginFormik = useFormik({
    initialValues: ginAddInitalValue,
    onSubmit: poSubmit,
    validationSchema: ginAddValidationSchema
  })

  console.log(ginFormik);
  

  const getPurchaseOrderById = async (id) => {
    const res = await getGinByIdApi(id)
    if (res.status === 200) {
      const ginData = res.data?.data
      ginFormik.setFieldValue("_id", ginData?._id)
      ginFormik.setFieldValue("vendorDetails", ginData.vendor)
      ginFormik.setFieldValue("vendorId", ginData.vendor._id)
      ginFormik.setFieldValue("createDate", ginData?.createDate)
      ginFormik.setFieldValue("remarks", ginData?.remarks || '')
      ginFormik.setFieldValue("signature", ginData?.signature || '')
      setSelectedItems(ginData?.items)
      // setTimeout(() => {
      getItems(ginData?.items, ginData.vendor._id);
      // }, 100)
      setGinDetails(ginData)
    }
  }

const getItems = async (items = [], id) => {
   try {
     const res = await getInventoryApi("", "", "");
     if (res.status === 200) {
       let fetchedItem = res.data?.data?.data
       let selected = fetchedItem?.map(item => {
         if (items.filter(el => el?.inventoryItemId === item?._id).length > 0) {

           let elem = items?.filter(el => el?.inventoryItemId === item?._id)[0]
           return {
             selected: true,
             ...elem
           }
         } else {
           return {
             selected: false,
             itemId: item?._id,
             inventoryItemId:item?.inventoryItemId || item?._id,
             availableQty:item?.availableQty,
             quantity: '',
             unitPrice: item?.unitPrice,
             itemDetails: item
           }
         }
       })

       ginFormik.setFieldValue("items", selected)
     }
   } catch (error) {

   }
 }

  const gettermsAndConditions = async () => {
    try {
      const res = await termsAndConditionsApi();
      if (res.status === 200) {
        console.log(res.data?.data)

        setTerms(res.data?.data?.allTerms?.map(term => ({ label: term.title, value: term})))
      }
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    if (ginId) {
      getPurchaseOrderById(ginId)
    }
  }, [])

  useEffect(() => {
    let taxItem = {};
    ginFormik.values.items?.forEach(item => {
      if (taxItem[item?.itemDetails?.tax]?.length > 0) {
        taxItem[item?.itemDetails?.tax].push(item)
      } else {
        taxItem[item?.itemDetails?.tax] = [item];
      }
    })
    setTaxItems(taxItem)
  }, [ginFormik.values.items])



//   useEffect(() => {
//     if (ginFormik.values.vendorId) {
//       getItems(ginId ? selectedItems : [], ginFormik.values.vendorId)
//     }
//   }, [ginFormik.values.vendorId])

useEffect(() => {
   getItems(ginId ? selectedItems : [],)
 }, [ginFormik.values.vendorId])

  const getTotalPoAmt = (items) => {
    let amt = 0
    items.forEach(item => {
      amt += (Number(item?.unitPrice) * Number(item?.quantity)) * (100 + Number(item?.itemDetails?.tax)) / 100
    })
    return formatPrice(amt)
  }

  const avargeTax = (items) => {
    if (items?.length > 0) {
      let tax = 0;
      items.forEach(item => {

        tax += Number(item?.itemDetails.tax)
      })
      return tax / items.length
    }
    return 0;
  }

  const totalPrice = (items) => {

    if (items?.length > 0) {
      let price = 0;
      items.forEach(item => {

        price += Number(item?.unitPrice) * Number(item?.quantity)
      })
      return price
    }
    return 0;
  }

  const totalAmount = (items) => {
    if (items?.length > 0) {
      let amt = 0
      items.forEach(item => {

        amt += (Number(item?.unitPrice) * Number(item?.quantity)) * (100 + Number(item?.itemDetails?.tax)) / 100
      })
      return amt
    }
  }


  const handleConfirmation = ()=>{
    ginFormik.handleSubmit();
  }

  const checkValidation = (btn)=>{
    const errors = Object.keys(ginFormik.errors)
    if(errors?.length>0){
      ginFormik.handleSubmit()
    }else{
      document.getElementById(btn).click()
    }
  }

  const handleApprovalModalClose=()=>{
     navigate("/gin")
  }

  const handleConvertModalClose =()=>{
    if(PoEditId){
      navigate(`/challan/Edit/${PoEditId}`);
    }
  }

const vendorTypeOptions=[
  {label:"Vendor",value:"Vendor"},
  {label:"Sub-Contractor",value:"Sub-Contractor"},
]

const handleVendorType =(e)=>{
  ginFormik.setFieldValue("type",e?.value)
  ginFormik.setFieldValue("vendorId","")
  ginFormik.setFieldValue("vendorDetails",{})
}



const clear = () => {
  sigCanvas.current.clear();
};

const save = async () => {
  try {
    const canvas = sigCanvas.current.getTrimmedCanvas();
    const blob = await new Promise(resolve => canvas.toBlob(resolve));
    const formData = new FormData();
    formData.append('file', blob, `signature${new Date().getTime()}.png`);
    formData.append("type", "gin")
    const res = await fileUploadApi(formData)
    if (res.status === 200 && res.data?.status === 1) {
      toast.success("File uploaded");

      ginFormik.setFieldValue("signature", res.data?.data)
      document.getElementById("signatureClose").click()
    }
  } catch (error) {
    console.log(error)
  }
}


  return (
    <>
      <div className="dash-nav1">
        <div className="back"><span onClick={() => navigate(-1)} className="back cursor-pointer" ><i className="bi bi-chevron-left"></i> Back</span></div>
        <div className="align-items-center d-block d-lg-flex justify-content-between">
          <h3 className="mb-0 font-blue">{ginId ? "Edit" : "Create New"} GIN</h3>
          {ginFormik.values.vendorId && <div>
            <button type="button" disabled={addLoader} className="mb-2 mb-sm-2 sbm-purchase" onClick={() => {
              if (ginFormik.values?.items.filter(item => item.selected)?.length) checkValidation("submitModalBtn1")
              else toast.error("Please select items")
            }}>
              {addLoader ? <Spinwheel sizeClass={"spin-small"} /> : "Submit for approval"}
            </button>
          </div>}
        </div>
      </div>
      <div className="dash-table">
        <div className="container">
          <div className="row gap-3 gap-xl-0">
            <div className="col-12 col-xl-3">
              <div className="vendor-detail ">
                <div className='d-flex align-items-center justify-content-between bb'>
                <h6 className='mb-0 pb-0' style={{border:"none"}}>Sub-contractor details</h6>
                {/* <button className="add-vendor mb-sm-0 mb-sm-2" data-bs-toggle="modal" data-bs-target={`#${addVendorModalId}`} onClick={() => VendorFormik.resetForm()}>
                  <i className="bi bi-plus"></i> Add new Vendor
                </button> */}
                </div>
                <form action="">
                <div className="form-details">
                    <label htmlFor="">Sub-contractor name</label>
                    <div className='d-flex align-items-center justify-content-center mb-3 '>
                      <div className='cursor-pointer w-100' >
                        <AsyncSelect style={{ cursor: "pointer !important" }}  className='col w-100' placeholder="Select vendor..." cacheOptions value={{ label: ginFormik.values?.vendorDetails?.name, value: ginFormik.values?.vendorDetails }} loadOptions={vendorOptions} defaultOptions onChange={(e) => { handleVendorSelect(e) }} />
                    </div>
                    {/* <i className="form-input bi bi-plus-square ms-2" style={{cursor:"pointer"}} data-bs-toggle="modal" data-bs-target={`#${addVendorModalId}`}></i> */}
                    </div>
                    {ginFormik?.touched?.vendorId && ginFormik?.errors?.vendorId ? (<div className='req-error'>{ginFormik?.errors?.vendorId}</div>) : null}
                  </div>

                  {ginFormik.values.vendorId && <>
                    <div className="form-details">
                      <div className="row">
                        <div className="col-lg-12">
                          <label htmlFor="startDate">Date of issue</label>
                          <DatePicker dateFormat="dd/MM/yyyy" placeholderText='Create date' className='form-select cursor-pointer' name='createDate' selected={ginFormik.values?.createDate} onChange={(e) => { handleSelectDate(e, "createDate") }} maxDate={new Date(ginFormik.values?.dueDate)} />
                          <span id="startDateSelected"></span>

                        </div>
                      </div>
                    </div>
                    <div className="form-details">
                      <label htmlFor="">GST number</label>
                      <input type="text" className="form-control" id="GST1" placeholder="Enter your GST number" disabled value={ginFormik.values?.vendorDetails?.gstNumber} />
                    </div>
                    {ginFormik?.values?.type!="Vendor" &&
                    <div className="form-details">
                      <label htmlFor="">PAN number</label>
                      <input type="text" className="form-control" id="GST1" placeholder="Enter your GST number" disabled value={ginFormik.values?.vendorDetails?.panNumber} />
                    </div>}

                    <h6 className="mt-4">Sub-contractor address</h6>
                    <div className="form-details">
                      <label htmlFor="">Flat, House no., Building, Company, Apartment</label>
                      <input type="text" className="form-control" disabled value={ginFormik.values?.vendorDetails?.vendorAddressId?.address1} />
                    </div>
                    <div className="form-details">
                      <label htmlFor="">Area, Street, Sector, Village</label>
                      <input className="w-100 form-control" type="text" placeholder="Enter area..." disabled value={ginFormik.values?.vendorDetails?.vendorAddressId?.address2} />
                    </div>
                    <div className="form-details">
                      <label htmlFor="">State</label>
                      <select className="form-control" aria-label="Small select example" disabled value={ginFormik.values?.vendorDetails?.vendorAddressId?.state}>
                        <option value={ginFormik.values?.vendorDetails?.vendorAddressId?.state}>{ginFormik.values?.vendorDetails?.vendorAddressId?.state}</option>
                      </select>
                    </div>
                    <div className="form-details">
                      <div className="row">
                        <div className="col-lg-6">
                          <label htmlFor="startDate">City</label>
                          <select className="form-control" disabled aria-label="Small select example" value={ginFormik.values?.vendorDetails?.vendorAddressId?.city}>
                            <option value={ginFormik.values?.vendorDetails?.vendorAddressId?.city}>{ginFormik.values?.vendorDetails?.vendorAddressId?.city}</option>
                          </select>
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="endDate">Pincode</label>
                          <input className="form-control" type="text" placeholder="Enter pin" disabled value={ginFormik.values?.vendorDetails?.vendorAddressId?.pinCode} />
                        </div>
                      </div>
                    </div>
                    <hr />                   
                    <div>
                      <div className='d-flex align-items-center'><div className='fs-5 fw-bold'>Signature of sub-contractor </div> &nbsp; <span className='req-error'>*</span></div>
                      <div className='py-2'>
                        <div className="d-flex border px-2 py-1 justify-content-between align-items-center ">
                          <div className="">
                            <div className='border rounded-image d-flex justify-content-center align-items-center'>
                              <img src={S3_URL + ginFormik.values?.signature} alt="" style={{ maxHeight: "85%", maxWidth: "85%" }} />
                            </div>
                            

                          </div>

                          <div className='signatureCanvasParent'>
                            <Popup
                              modal
                              trigger={<div className='create-order cursor-pointer' >
                                Add Signature
                              </div>}
                              closeOnDocumentClick={false}

                            >
                              {close => (
                                <>
                                  <SignaturePad
                                    ref={sigCanvas}
                                    canvasProps={{
                                      className: "signatureCanvas"
                                    }}
                                  />

                                  <div className='bg-dark-soft p-2'>
                                    <button className='btn btn-primary mx-1' id="signatureSave" onClick={save} >Upload</button>
                                    <button className='btn btn-primary mx-1' id="signatureClear" onClick={clear}>Clear</button>
                                    <button className='btn btn-primary mx-1' id="signatureClose" onClick={close}>Close</button>
                                  </div>
                                </>
                              )}
                            </Popup>
                          </div>
                        </div>
                        {(ginFormik?.touched?.signature || submitClick) && ginFormik?.errors?.signature ? (<div className='req-error'>{ginFormik?.errors?.signature}</div>) : null}
                      </div>
                    </div>
                  </>}
                </form>
              </div>
            </div>
            <div className="col-12 col-xl-5">
              <div className="items-detail-box position-relative ">
                <div className='d-flex justify-content-between align-items-center bb'>
                <h6 className='mb-0 pb-0' style={{border:"none"}}>Items details</h6>
                {/* {ginFormik.values.vendorId && <button className="add-vendor mb-sm-0 mb-sm-2" data-bs-toggle="modal" data-bs-target={`#${addItemModalId}`} onClick={() => { itemFormik.resetForm(); setSelectItemId("") }}>
                  <i className="bi bi-plus"></i> Add new Item
                </button>} */}
                </div>

                <table className="table table-responsive">
                  <thead>
                    <tr className='w-100'>
                      <th scope="col" className='w-50'>ITEMS</th>
                      <th scope="col">QTY</th>
                      <th scope="col">UNIT</th>
                      <th scope="col">Available Qty</th>
                      {/* <th scope="col">GST</th> */}
                      {/* <th scope="col">AMOUNT</th> */}
                      {/* <th scope="col">EDIT</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {ginFormik.values?.vendorId && <>
                      {ginFormik?.values?.items?.map((ele, index) => {
                        return (<tr>
                          <td><td><input className="form-check-input" type="checkbox" checked={ginFormik?.values?.items?.[index]?.selected} name={`items[${index}].selected`} id="selected" onChange={(e) => { checkHandler(e, ele) }} /></td>
                           <td style={{overflowWrap:"anywhere"}} className=''>{ele?.itemDetails?.name}</td>
                           </td>
                          <td>
                            {/* <div className="qt-box">120</div> */}
                            <div>
                              <input type="text" className="form-control qt-box" name={`items[${index}].quantity`} id="quantity" value={ginFormik?.values?.items?.[index]?.quantity} onChange={(e) => {
                                if (/^\d*\.?\d*$/.test(e.target.value)) {
                                  ginFormik.handleChange(e);
                                }
                              }} onBlur={ginFormik.handleBlur} disabled={!ginFormik?.values?.items?.[index]?.selected} />
                              {ginFormik?.touched?.items?.[index]?.quantity && ginFormik?.errors?.items?.[index]?.quantity ? (<div className='req-error'>{ginFormik?.errors?.items?.[index]?.quantity}</div>) : null}</div>
                          </td>
                          <td>
                            <div>
                              <input type="text" className="form-control qt-box" name={`items[${index}].itemDetails.unitType`} id="unitType" value={ginFormik?.values?.items?.[index]?.itemDetails?.unitType}  disabled={true} />
                              </div>
                          </td>
                          <td>
                            <div>
                              <input type="text" className="form-control qt-box" name={`items[${index}].availableQty`} id="availableQty" value={ginFormik?.values?.items?.[index]?.availableQty}  disabled={true} />
                              </div>
                          </td>
                          {/* <td>
                            <div>
                              <input type="text" className="form-control un-box" id="exampleInputEmail1" name={`items[${index}].unitPrice`} value={ginFormik?.values?.items?.[index]?.unitPrice} onChange={(e) => {
                                if (/^\d*\.?\d*$/.test(e.target.value)) {
                                  ginFormik.handleChange(e);
                                }
                              }} onBlur={ginFormik.handleBlur} disabled={!ginFormik?.values?.items?.[index]?.selected} />
                              {ginFormik?.touched?.items?.[index]?.unitPrice && ginFormik?.errors?.items?.[index]?.unitPrice ? (<div className='req-error'>{ginFormik?.errors?.items?.[index]?.unitPrice}</div>) : null}
                            </div>
                          </td> */}
                          {/* <td>{ele?.itemDetails?.tax}%</td> */}
                          {/* <td>{formatPrice((Number(ginFormik?.values?.items?.[index]?.unitPrice) * Number(ginFormik?.values?.items?.[index]?.quantity)))}</td> */}
                          {/* <td><i data-bs-toggle="modal" data-bs-target={`#${addItemModalId}`} onClick={() => { itemFormik.setValues(ele?.itemDetails); itemFormik.setFieldValue('totalQuantity', ginFormik?.values?.items?.[index]?.quantity); itemFormik.setFieldValue('unitPrice', ginFormik?.values?.items?.[index]?.unitPrice) ; setSelectItemId(ele?.itemDetails?._id) }} className="bi bi-pencil-square point"></i></td> */}
                        </tr>)
                      })
                      }
                    </>}

                  </tbody>
                </table>
                <hr />
                {/* <div className="form-details">
                  <label htmlFor="remarks" className="form-label">Remarks</label>
                  <textarea className="w-100 form-control" type="text" name={`remarks`} placeholder="Enter note..." disabled="" onChange={ginFormik.handleChange} value={ginFormik?.values?.remarks} />
                  {ginFormik?.touched?.remarks && ginFormik?.errors?.remarks ? (<div className='req-error'>{ginFormik?.errors?.remarks}</div>) : null}
                </div> */}

                {ginFormik?.values?.items > 0 && <div className="tm-box">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="dark mb-0">Total amount</p>
                    <p className="light mb-0">{getTotalPoAmt(ginFormik?.values?.items)}</p>
                  </div>
                </div>}
              </div>
            </div>
            <div className="col-12 col-xl-4">
              <div className="order-review">
                <div className="d-flex bb justify-content-between align-items-center">
                  <h6>Preview</h6>

                </div>
                {ginFormik.values.vendorId && <div className="org">
                  <div className="d-flex  justify-content-between align-items-center">
                    <h4 className="mb-3">{ginFormik.values?.vendorDetails?.name}</h4>
                    {/* {ginFormik.values.vendorId && <div onClick={() => { editVendorHandler(ginFormik.values.vendorDetails) }} className='cursor-pointer'><i className="bi bi-pencil-square"></i></div>} */}
                  </div>

                  {ginDetails?.ginNumber && <div className="d-flex justify-content-between align-items-center">
                    <p className="dark">GIN number</p>
                    <p className="light">{ginDetails?.ginNumber}</p>
                  </div>}

                  <div className="d-flex justify-content-between align-items-center">
                    <p className="dark">Date of issue</p>
                    <p className="light">{moment(ginFormik.values?.createDate).format("DD/MM/YYYY")}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="dark">GST number</p>
                    <p className="light">{ginFormik.values?.vendorDetails?.gstNumber}</p>
                  </div>
                  {ginFormik.values?.vendorDetails?.type!="Vendor" &&
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="dark">PAN number</p>
                    <p className="light">{ginFormik.values?.vendorDetails?.panNumber}</p>
                  </div>}
                  {/* <div className="d-flex justify-content-between align-items-center">
                    <p className="dark">GIN due date</p>
                    <p className="light">{ginFormik.values?.dueDate && moment(ginFormik.values?.dueDate).format("DD/MM/YYYY")}</p>
                  </div> */}
                  {ginDetails?.status && <div className="d-flex justify-content-between align-items-center">
                    <p className="dark">Status</p>
                    <p className="light">{ginDetails?.status}</p>
                  </div>}
                  <div className="add-box">
                    <h5>Address:</h5>
                    {ginFormik.values.vendorId && <p className="mb-0">{ginFormik.values?.vendorDetails?.vendorAddressId?.address1}, {ginFormik.values?.vendorDetails?.vendorAddressId?.address2}, {ginFormik.values?.vendorDetails?.vendorAddressId?.city}, {ginFormik.values?.vendorDetails?.vendorAddressId?.state}, {ginFormik.values?.vendorDetails?.vendorAddressId?.pinCode}</p>}
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h6>Items (count: {ginFormik?.values?.items?.filter(item => item?.selected)?.length})</h6>
                    {/* <i className="bi bi-pencil-square"></i> */}
                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th  scope="col">NAME</th>
                        <th scope="col">QTY</th>
                        {/* <th scope="col">RATE</th> */}
                        {/* <th scope="col">GST</th> */}
                        {/* <th scope="col">AMOUNT</th> */}
                        {/* <th scope="col">EDIT</th> */}
                      </tr>
                    </thead>
                    <tbody>

                      {ginFormik.values?.vendorId && <>
                        {ginFormik?.values?.items?.filter(item => item?.selected)?.map((ele, index) => {
                          // console.log(ele)
                          return (<tr>
                            <td style={{ overflowWrap: "anywhere" }}>{ele?.itemDetails?.name}</td>
                            <td>
                              {ele?.quantity}
                            </td>
                            {/* <td> {ele?.unitPrice} </td> */}
                            {/* <td>{ele?.itemDetails?.tax}%</td> */}
                            {/* <td>{formatPrice(ele?.quantity * ele?.unitPrice)}</td> */}
                            {/* <td><i data-bs-toggle="modal" data-bs-target={`#${addItemModalId}`} onClick={() => { itemFormik.setValues(ele?.itemDetails);setSelectItemId(ele?.itemDetails?._id) }} className="bi bi-pencil-square point"></i></td> */}
                          </tr>)
                        })
                        }
                      </>}
                    </tbody>
                  </table>
                  {/* <div className="amount-box">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="dark">Amount</p>
                      <p className="light">{formatPrice(totalPrice(ginFormik?.values?.items?.filter(item => item?.selected)))}</p>
                    </div>

                    {(Object.keys(taxItems).sort((a, b) => b - a)).map((item, index) => {

                      return (
                        taxItems[item]?.filter(i => i?.selected)?.length ? <div key={index}>
                          <div className="d-flex justify-content-between gap-2 ">
                            <p className="dark">CGST ({item / 2}% on {totalPrice(taxItems[item]?.filter(i => i?.selected))?.toFixed(2)}) </p>
                            <p className="light">{formatPrice((totalPrice(taxItems[item]?.filter(i => i?.selected)) * item / 2) / 100)}</p>
                          </div>
                          <div className="d-flex justify-content-between gap-2 ">
                            <p className="dark">SGST ({item / 2}% on {totalPrice(taxItems[item]?.filter(i => i?.selected))?.toFixed(2)}) </p>
                            <p className="light">{formatPrice((totalPrice(taxItems[item]?.filter(i => i?.selected)) * item / 2) / 100)}</p>
                          </div>
                        </div> : null
                      )
                    })}
                    <div className="d-flex total justify-content-between align-items-center">
                      <p>R/off</p>
                      <p>{getRoundOff(totalAmount(ginFormik?.values?.items?.filter(item => item?.selected))).toFixed(2)}</p>
                    </div>
                    <div className="d-flex total justify-content-between align-items-center">
                      <p>Total amount</p>
                      <p>{formatPrice(Math.round(totalAmount(ginFormik?.values?.items?.filter(item => item?.selected))))}</p>
                    </div>
                  </div> */}
                </div>}
                {ginFormik.values.remarks && <div className="add-box">
                  <h5>Remarks:</h5>
                   <p className="mb-0">{ginFormik.values?.remarks}</p>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ItemsAddModal formik={itemFormik} modalId={addItemModalId} addLoader={addItemLoader} type="po" removeItem={removeItem} selectItem={selectItemId} />
      <VendorAddModal formik={VendorFormik} modalId={addVendorModalId} addLoader={addItemLoader} />
      <div className="col">
        <div className="btn btn-primary hidden" style={{ display: "none" }} data-bs-toggle="modal" id='vendorCreate' data-bs-target={`#${addVendorModalId}`} onClick={() => { itemFormik.resetForm() }} >Create Vendor</div>
      </div>
      <ConfirmationModal modalId={ItemDeleteModalId} handleConfirm={handleItemDelete} message={<>Are you sure you want to <br /> Delete ?</>} />
      {/* for po approval */}
      <button className='d-none' id="submitModalBtn1" data-bs-toggle="modal" data-bs-target={`#${submitConfirmModal}`} />
      <ConfirmationModal modalId={submitConfirmModal} handleConfirm={()=>handleConfirmation(false)} message={<>Are you sure you want to <br /> Submit ?</>} />
       <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`} />
        <StatusModal
        modalId={statusModalId}
        message={confirmationMsg}
        handleClose={handleApprovalModalClose}
        />

        {/* for po convert to purchase */}
        <button className='d-none' id="convertModalBtn" data-bs-toggle="modal" data-bs-target={`#${convertConfirmModal}`} />
      <ConfirmationModal modalId={convertConfirmModal} handleConfirm={() => handleConfirmation(true)} message={<>Are you sure you want to submit and <br /> convert to Purchase order?</>} />

    </>
  )
}

export default AddGIN