import React, { useEffect, useState } from 'react'
import Spinwheel from '../common/Spinwheel'
import { useUserContext } from '../../context/userContext';

function InventoryItemModal(props) {
  const { modalId, formik, disable } = props
  const {userDetails} = useUserContext()

  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">

              <button type="button" id="confirmModalCloseBtn" className="btn-close1" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg"></i></button>
            </div>
            <div className="modal-body ">
              <div className="">
                <div className="bb">
                  <h5>Inventory Item</h5>
                </div>
                <form onSubmit={formik?.handleSubmit} className="my-fx">

                  <div className="ed-box">
                    <label htmlFor="name" className="form-label">Name</label>
                    <input type="text" className="form-control" id="name" placeholder="Enter " disabled={true} name='name' value={formik?.values?.name} />
                  </div>
                  <div className="ed-box">
                    <label htmlFor="hsnCode" className="form-label">HSN Code</label>
                    <input type="text" className="form-control" id="hsnCode" placeholder="Enter " disabled={true} name='hsnCode' value={formik?.values?.hsnCode} />
                  </div>
                  <div className="ed-box">
                    <label htmlFor="availableQty" className="form-label">Available quantity</label>
                    <input type="text" className="form-control" id="availableQty" placeholder="Enter " disabled={true} name='availableQty' value={formik?.values?.availableQty} />
                  </div>
                  <div className="ed-box">
                    <label htmlFor="unitType" className="form-label">Unit</label>
                    <input type="text" className="form-control" id="unitType" placeholder="Enter " disabled={true} name='unitType' value={formik?.values?.unitType} />
                  </div>
                  <div className="ed-box">
                    <label htmlFor="minQty" className="form-label">Notify below(quantity)</label>
                    <input type="text" className="form-control" id="minQty" placeholder="Enter " name='minQty' disabled={!userDetails?.roleId?.permission?.includes("inventoryWrite")} value={formik?.values?.minQty} onChange={formik?.handleChange} onBlur={formik?.handleBlur}  />
                    {formik?.touched?.minQty && formik?.errors?.minQty ? (<div className='req-error'>{formik?.errors?.minQty}</div>) : null}
                  </div>
                  <div className="d-flex mt-4 justify-content-end align-items-center">
                  {userDetails?.roleId?.permission?.includes("inventoryWrite") && <button disabled={disable} type='submit' className="save">
                      Save
                    </button> }  
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default InventoryItemModal