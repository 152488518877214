import React, { useEffect, useState } from 'react'
import { approvePurchaseOrderApi, getAllGinApi, getPurchaseOrderApi } from '../../utils/ApiEndpointFunctions';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { MONTHS } from '../../utils/constant';
import Spinwheel from '../../components/common/Spinwheel';
import { useUserContext } from '../../context/userContext';
import toast from 'react-hot-toast';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import Filter from '../../components/modal/Filter';
import { useFormik } from 'formik';
import moment from 'moment/moment';
import ReactPaginate from 'react-paginate';
import { formatPrice } from '../../utils/HelperFuncitons';
import StatusModal from '../../components/modal/StatusModal';

export default function GinTable(props) {
  const [gin, setgin] = useState([])
  const [loading, setLoading] = useState({ gin: true })
  const navigate = useNavigate();
  const { userDetails } = useUserContext();
  const confirmModalId = "conFirmModalID"
  const filterModalId = "filterModalID"
  const statusModalId = "statusModal"
  const [selectGin, setSelectGin] = useState({id:null,ginNo:null});
  const limit = 10;
  const [page, setPage] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  // let [selectedPage, setSelectedPage] = useState(searchParams.get("page") == null ? 1 : searchParams.get("page"));
  const location = useLocation()

  const [totalCount, setTotalCount] = useState(null);

  const getAllGin = async (filterReset) => {
    setLoading({ ...loading, gin: true });
    let filter = filterReset === "reset" ? ["", "", "", "", "", ""] : [
      filterFormik.values?.itemId,
      `${filterFormik.values?.to ? new Date(filterFormik.values?.to).getTime() + "-" : ""}${filterFormik.values?.from ? new Date(filterFormik.values?.from).getTime() : ""}`,
      filterFormik.values?.hsnCode,
      filterFormik.values?.number,
      filterFormik.values?.status,
      filterFormik.values?.vendorName,
    ];
    try {
      const page = searchParams.get("page") || 1
      const res = await getAllGinApi(...filter, limit, page);
      if (res.status === 200) {
        setgin(res.data?.data?.gin);
        setTotalCount(res.data?.data?.totalCount);
        setPage(Math.ceil(res.data?.data?.totalCount / limit));
      }
    } catch (error) {
      console.log(error)
    }
    setLoading({ ...loading, gin: false });
  }

  const handlePageClick = (event) => {
    let currentPage = event.selected + 1;

    const newParams = new URLSearchParams(searchParams);
    newParams.set("page", currentPage);

    if (newParams.has("tab")) {
      newParams.set("tab", newParams.get("tab"));
    }

    setSearchParams(newParams);
  };


  const handlePoApproval = async () => {
    try {
      const res = await approvePurchaseOrderApi({ id: selectGin.id })
      if (res.status === 200) {
        toast.success(res.data?.message)
        document.getElementById(confirmModalId).click()
        document.getElementById("statusModalBtn").click()
        getAllGin()
      }
    } catch (error) {
      console.log(error)
      toast.error("something went wrong");
    }
  }

  const calculateAmount = (ele) => {
    let amount = 0;
    ele.forEach((item) => {
      amount += (item.quantity * item.unitPrice) * ((100 + item.itemDetails.tax) / 100)
    })
    return amount
  }

  const handleFilterPo = () => {
    if (searchParams.get('page') == 1) {
      getAllGin()
    } else {
      setSearchParams({ "page": 1 })
    }
  }

  const filterFormik = useFormik({
    initialValues: {
      Item: "",
      hsnCode: "",
      number: "",
      status: "",
      vendorName: "",
      to: "",
      from: "",
      itemId:""
    }
  })

  // useEffect(() => {
  //   getAllGin()
  // }, [])

  useEffect(() => {
      getAllGin()
  },[searchParams.get('page')])

  return (
    <>

      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="main-content">

              {props.type !== "dashboard" && <div className="dash-nav1">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="mb-0 font-blue">GIN</h3>
                  {userDetails?.roleId?.permission?.includes("ginWrite") && <button onClick={() => { navigate("/gin/add"); }} className="create-order mb-3">Create GIN</button>}
                </div>
              </div>}
              <div className="d-flex justify-content-end align-items-end">
                <div className="d-flex align-items-center justify-content-end">
                  <div className="dropdown">
                    <button className="btn btn-dark me-2" type="button" disabled={!Object.values(filterFormik?.values).filter(Boolean).length} onClick={() => { filterFormik.resetForm(); getAllGin("reset") }} >
                      Reset filter
                    </button>
                    <button className="btn filter  dropdown-toggle" type="button" data-bs-toggle="modal" data-bs-target={`#${filterModalId}`}  >
                      <i className="bi bi-funnel me-2"></i>Filter
                    </button>
                  </div>
                </div>
              </div>
              {/* <!-- table --> */}
              <div className="table-responsive" style={{ overflowX: "inherit" }}>
                <table className="table d-table mt-4">
                  <thead>
                    <tr>
                      <th scope="col">GIN NUMBER</th>
                      <th scope="col">ITEMS COUNT.</th>
                      {/* <th scope="col">AMOUNT</th> */}
                      <th scope="col">VENDOR NAME</th>
                      <th scope="col">GST NUMBER</th>
                      <th scope="col">PAN NUMBER</th>
                      <th scope="col">CREATED DATE</th>
                      {/* <th scope="col">DUE DATE</th> */}
                      <th scope="col">STATUS</th>
                      <th scope="col">ACTION</th>
                    </tr>
                  </thead>
                  {
                    loading.gin ?
                      <tbody >
                        <tr>
                          <td colSpan={12}>
                            <div className="d-flex justify-content-center">
                              <Spinwheel />
                            </div>
                          </td>
                        </tr>
                      </tbody> :
                     
                        <tbody>
                          {gin?.length > 0 ? gin?.map((ele, index) => {
                            return (
                              <tr key={index}>
                                <td>{ele?.ginNumber}</td>
                                <td>{ele?.items?.length}</td>
                                {/* <td>{formatPrice(ele?.amount)}</td> */}
                                <td>{ele?.vendor?.name}</td>
                                <td>{ele?.vendor?.gstNumber}</td>
                                <td>{ele?.vendor?.panNumber}</td>
                                <td>{moment(ele?.createDate).format("DD/MM/YYYY")}</td>
                                {/* <td>{moment(ele?.dueDate).format("DD/MM/YYYY")}</td> */}
                                <td><button className={` cursor-text text-capitalize ${ele?.status?.toLowerCase() == "approved" ? "approve" : (ele?.status?.toLowerCase() == "reject" ? "reject" : "sbm")} `}>{ele?.status}</button></td>
                                <td>
                                  <div className="d-flex justify-content-start align-items-center">
                                    <div>
                                      {/* <>
                                      {ele?.status?.toLowerCase() === "submitted" ? <>
                                        {userDetails?.roleId?.permission.includes("poRead") && <button className="view" onClick={() => { navigate(`/purchase/view/${ele?._id}`) }}>View</button>}
                                      </> : <button className="apr">Approve</button>}</> */}
                                      {userDetails?.roleId?.permission.includes("poReject") && ele?.status?.toLowerCase() !== "reject" && <button className="reject">Reject</button>}
                                    </div>
                                    <div>
                                      <div className="dropdown">
                                        <button className="dots dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-three-dots-vertical"></i></button>
                                        <ul className="dropdown-menu">
                                          {userDetails?.roleId?.permission.includes("ginRead") && <li className='dropdown-item' onClick={() => { navigate(`/gin/view/${ele?._id}`) }} >View</li>}
                                          {(((userDetails?.roleId?.permission.includes("ginUpdate")) && ele?.status?.toLowerCase() != "approved") || ele?.status?.toLowerCase() == "created") && <li className='dropdown-item' onClick={() => { navigate(`/gin/update/${ele?._id}`) }} >Edit</li>}
                                          {userDetails?.roleId?.permission.includes("ginApprove") && ele?.status?.toLowerCase() != "approved" &&
                                            <li className='dropdown-item' data-bs-toggle="modal" data-bs-target={`#${confirmModalId}`} onClick={() => { setSelectGin({id:ele?._id,ginNo:ele?.poNumber}) }} >Approve</li>}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>)
                          }) : <tr className='text-center'>
                            <td colSpan="12">
                              <h1>Data Not Found</h1>
                            </td>
                          </tr>}
                        </tbody>}
                </table>
              </div>
              {totalCount > limit ? (
                <div className="d-flex justify-content-center">
                  <ReactPaginate
                    nextLabel="Next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    pageCount={page}
                    previousLabel="< Previous"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName={"active"}
                    forcePage={searchParams.get('page')? Number(searchParams.get('page'))-1 : 0}
                  />
                </div>
              ) : (
                ""
              )}
              <ConfirmationModal modalId={confirmModalId} handleConfirm={handlePoApproval} message={<>Are you sure you want to <br /> Approve ?</>} />
              <Filter modalId={filterModalId} formik={filterFormik} clickHandler={handleFilterPo} filterType={"gin"}/>
              <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`}/>
              <StatusModal 
                modalId={statusModalId}
                message={`GIN ${selectGin.ginNo || ""} is successfully approved.`}
              />

            </div>
          </div>
        </div>
      </div>
    </>
  )
}
