import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import Logo from "./"
import { useLocation } from 'react-router-dom';
import { useUserContext } from '../../context/userContext';

export default function MainSidebar() {
  const navigate = useNavigate();
  const location = useLocation()
  const token = localStorage.getItem("token")
  const { userDetails } = useUserContext();

  const handleLogout = () => {
    localStorage.removeItem("token");

    window.location.reload();
    navigate("/");
  }

  const path = location.pathname
  return (
    <>
      {token && <div className="side-nav">
        <div className="nav flex-column nav-pills " id="v-pills-tab" role="tablist" aria-orientation="vertical">
          <Link to="/dashboard" className={`nav-link ${path.includes("dashboard") ? "active" : ""}`}> <i className="bi bi-house-door-fill"></i> Dashboard</Link>
          {userDetails?.roleId?.permission.includes("poRead") &&
            <Link to="/purchase" className={`nav-link ${path.includes("purchase") ? "active" : ""}`}><span className='pr-2'><img src='/purchase_order.png' className='tab-icon'/></span> Purchase Order</Link>
          }
          {userDetails?.roleId?.permission.includes("challanRead") &&
            <Link to="/challan" className={`nav-link ${path.includes("challan") ? "active" : ""}`} ><span className='pr-2'><img src='/challan.png' className='tab-icon'/></span> Challan</Link>
          }
          {userDetails?.roleId?.permission.includes("voucherRead") &&
            <Link to="/voucher" className={`nav-link ${path.includes("voucher") ? "active" : ""}`} ><span className='pr-2'><img src='/voucher.png' className='tab-icon'/></span> Voucher</Link>
          }
            {userDetails?.roleId?.permission.includes("cashbookRead") &&
          <Link to="/cashbook" className={`nav-link ${path.includes("cashbook") ? "active" : ""}`} ><span className='pr-2'><img src='/my_cashbook.png' className='tab-icon'/></span> My Cashbook</Link>}
          {userDetails?.roleId?.permission.includes("uploadRead") && <Link to="/upload" className={`nav-link ${path.includes("upload") ? "active" : ""}`}><span className='pr-2'><img src='/upload.png' className='tab-icon'/></span> Upload</Link>}
          {userDetails?.roleId?.permission.includes("reportRead") && <Link to="/report" className={`nav-link ${path.includes("report") ? "active" : ""}`}><span className='pr-2'><img src='/my_cashbook.png' className='tab-icon'/></span> Report</Link>}
          {userDetails?.roleId?.permission.includes("inventoryRead") && <Link to="/inventory" className={`nav-link ${path.includes("inventory") ? "active" : ""}`}><span className='pr-2'><img src='/my_cashbook.png' className='tab-icon'/></span> Inventory</Link>}
          {userDetails?.roleId?.permission.includes("ginRead") && <Link to="/gin" className={`nav-link ${path.includes("gin") ? "active" : ""}`}><span className='pr-2'><img src='/my_cashbook.png' className='tab-icon'/></span> GIN</Link>}
          {/* <div className="logout">
            <Link to="/" data-bs-toggle="modal"
              data-bs-target="#exampleModalout1" className="nav-link">
              <i className="bi bi-box-arrow-left"></i>
              Log Out
            </Link>
          </div> */}

        </div>
        {/* Log out popup code start here */}

      </div>}
    </>

  )
}
