import * as Yup from 'yup'
import { validatePOChallanOrVoucherNumber } from './HelperFuncitons'


const paths = window.location.pathname?.split('/')

// admin sign in
export const SignInInitaialValue = {
  phoneNumber: "",
}



export const SignInValidationSchema = Yup.object({
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .matches(/^\+?[1-9]\d{9,12}$/, 'Invalid phone number')
})

export const OtpverificationInitial = {
  otp: "",
}

export const OtpValidationSchema = Yup.object({
  otp: Yup.string()
  .max(6,"Otp must have 6 digits")
    .required('OTP is required')
    .matches(/^[0-9]{6}$/, 'Invalid OTP')
})

export const poAddInitalValue = {
  // siteId: "",
  type:"",
  vendorId: "",
  createDate: new Date().toISOString(),
  dueDate: "",
  remarks:"",
  items: [],
  termsAndCondition: {
    title:"",
    description:"",
  }
}

export const poAddValidationSchema = Yup.object().shape({
  // siteId: Yup.string().trim().required('Site ID is required'),
  type: Yup.string().trim().required('Type is required'),
  vendorId: Yup.string().trim().required('Vendor ID is required'),
  createDate: Yup.string().trim().required('Create Date is required'),
  dueDate: Yup.string().trim().required('Due Date is required'),
  termsAndCondition:  Yup.object().shape({
    title: Yup.string()
      .required('T&C is required')
  }),
  items: Yup.array().of(
    Yup.object().shape({
      itemId: Yup.string().trim().required('Item ID is required'),
      // quantity: Yup.number().typeError('Quantity must be a number').positive('Quantity must be a positive number').required('Quantity is required'),
      quantity: Yup.string().test('quantity', 'Quantity must be greater than zero', function (value) {
        const { selected } = this.parent;
        if (selected) {
          if (!value || Number(value) <= 0) {
            return false
          }
        }
        return true;
      }),

      unitPrice: Yup.string().test('unitPrice', 'UnitPrice must be greater than zero', function (value) {
        const { selected } = this.parent;
        if (selected) {
          if (!value || Number(value) <= 0) {
            return false
          }
        }
        return true;
      }),
    })
  ),
});

export const ginAddInitalValue = {
  vendorId: "",
  createDate: new Date().toISOString(),
  dueDate: "",
  remarks:"",
  items: [],
  signature:"",
}

export const ginAddValidationSchema = Yup.object().shape({
  vendorId: Yup.string().trim().required('Vendor ID is required'),
  createDate: Yup.string().trim().required('Date of issue is required'),
  signature: Yup.string().trim().required('Signature is required'),
  items: Yup.array().of(
    Yup.object().shape({
      inventoryItemId: Yup.string().trim().required('Item ID is required'),
      // quantity: Yup.number().typeError('Quantity must be a number').positive('Quantity must be a positive number').required('Quantity is required'),
      quantity: Yup.string()
      .test('quantity', 'Quantity must be greater than zero', function (value) {
        const { selected } = this.parent;
        if (selected) {
          if (!value || Number(value) <= 0) {
            return false;
          }
        }
        return true;
      })
      .test('availableQty', 'Quantity must not exceed available quantity', function (value) {
        const { availableQty, selected } = this.parent;
        if (selected) {
          if (value && Number(value) > Number(availableQty)) {
            return false;
          }
        }
        return true;
      }),
    
      

      // unitPrice: Yup.string().test('unitPrice', 'UnitPrice must be greater than zero', function (value) {
      //   const { selected } = this.parent;
      //   if (selected) {
      //     if (!value || Number(value) <= 0) {
      //       return false
      //     }
      //   }
      //   return true;
      // }),
    })
  ),
});

export const addItemInitialValue = {
  _id: "",
  isDisabled: true,
  name: "",
  localName: "",
  description: "",
  unitType: "",
  hsnCode: "",
  tax: "18",
  unitPrice: "",
  totalQuantity: "",
  otherUnit:"",
  
}

export const addItemValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Name is required'),
  localName: Yup.string().trim().required('Local Name is required'),
  description: Yup.string().trim().required('Description is required'),
  hsnCode: Yup.string().trim().required('HSN Code is required'),
  unitType: Yup.string().trim().required('Unit Type is required'),
  // ...(paths.includes('challan') ? {
  //   unitPrice: Yup.number().typeError('Unit price must be a number').positive('Unit price must be a positive number').required('Unit price is required'),
  //   totalQuantity: Yup.number().typeError('Quantity must be a number').positive('Quantity must be a positive number').required('Quantity is required')
  // }
  //   : {}),
  otherUnit:Yup.string().trim().test('other-unit', 'Other unit is required ', function (value) {
    if (this.parent.unitType?.toLowerCase() == 'other') {
      return value && value.trim() !== '';
    }
    return true;
  }),
});

export const vendorAddInitailValue = {
  isDisabled: true,
  name: "",
  type:"",
  gstNumber: "",
  panNumber: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  pinCode: "",
  bankName: "",
  bankAccountNo: "",
  phone: "",
  email: "",
  IFSC:"",
  otherCity:"",
  specialization:"",
  nickName:"",
}

export const vendorAddValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Name is required'),
  type: Yup.string().trim().required('Type is required'),
  // gstNumber: Yup.string().trim().required('GST is required'),
  gstNumber: Yup.string().trim().test('check-vendor', 'GST is required ', function (value) {
    if (this.parent.type?.toLowerCase() == 'vendor') {
      return value && value.trim() !== '';
    }
    return true;
  }),
  panNumber: Yup.string().trim().test('check-vendor-pan', 'PAN is required ', function (value) {
    if (this.parent.type?.toLowerCase() != 'vendor') {
      return value && value.trim() !== '';
    }
    return true;
  }),
  address1: Yup.string().trim().required('Address1 is required'),
  address2: Yup.string().trim().required('Address2 is required'),
  city: Yup.string().trim().required('City is required'),
  state: Yup.string().trim().required('State is required'),
  pinCode: Yup.string().trim().required('Pin code is required').matches(/^\+?[1-9]\d{5}$/, 'Invalid pin code'),
  bankName: Yup.string().trim().required('Bank name is required'),
  bankAccountNo: Yup.number().typeError('Account number must be a number').required('Account number is required'),
  phone: Yup.string()
    .required('Phone is required')
    .matches(/^\+?[1-9]\d{9,11}$/, 'Invalid phone number'),
  email: Yup.string().email().required('Email is required'), 
  IFSC: Yup.string().required('Bank IFSC is required'),
  otherCity: Yup.string().trim().test('other-city', 'Other city name is required ', function (value) {
    if (this.parent.city?.toLowerCase() == 'other') {
      return value && value.trim() !== '';
    }
    return true;
  }),
});


export const challanInitalValue = {
  id: "",
  truckNumber: "",
  taxInvoiceNumber: "",
  taxInvoiceDate: "",
  items: [{
    itemId: "",
    quantity: "",
    unitPrice: "",
    isBreakage: false,
    breakage: "",
    isTrack:true,
  }],
  truckFrontSide: "",
  truckBackSide: "",
  beforeUnload: "",
  afterUnload: "",
  challanFrontSide: "",
  challanBackSide: "",
  invoiceFrontSide: "",
  invoiceBackSide: "",
  otherFiles: [{ description: "", image: "" }, { description: "", image: "" }, { description: "", image: "" }, { description: "", image: "" }, { description: "", image: "" }],
  signature: "",
  remarks:"",
}

export const challanValidationSchema = Yup.object().shape({
  id: Yup.string().trim().required('ID is required'),
  truckNumber: Yup.string().trim().required('Truck number is required'),
  // taxInvoiceNumber: Yup.string().trim().required('Tax invoice number is required'),
  taxInvoiceDate: Yup.date().required('Tax invoice date is required'),
  truckFrontSide: Yup.string().trim().required('Truck front side image is required'),
  truckBackSide: Yup.string().trim().required('Truck back side image is required'),
  // beforeUnload: Yup.string().trim().required('Before unload image is required'),
  // afterUnload: Yup.string().trim().required('After unload image is required'),
  challanFrontSide: Yup.string().trim().required('Challan front side image is required'),
  challanBackSide: Yup.string().trim().required('Challan back side image is required'),
  // invoiceFrontSide: Yup.string().trim().required('Invoice front side image is required'),
  // invoiceBackSide: Yup.string().trim().required('Invoice back side image is required'),
  otherFiles: Yup.array().of(
    Yup.object().shape({
      description: Yup.string().test('description-required', 'Description is required', function (value) {
        const image = this.parent.image;
        if (image && image.length > 0) {
          return !!value;
        }
        return true;
      }),
      image: Yup.string()
    })
  ),
  signature: Yup.string().trim().required('Signature is required'),
  // items: Yup.array().min(1, 'At least one item is required'),
  items: Yup.array().min(1, 'At least one item is required').of(
    Yup.object().shape({
      itemId: Yup.string().trim().required('Item ID is required'),
      quantity: Yup.number().typeError('Quantity must be a number').positive('Quantity must be a positive number').required('Quantity is required'),
      // quantity: Yup.string().test('quantity', 'Quantity must be a positive number', function (value) {
      //   const { selected } = this.parent;
      //   if (selected) {
      //     if (!value || Number(value) <= 0) {
      //       return false
      //     }
      //   }
      //   return true;
      // }),

      unitPrice: Yup.number().typeError('Unit Price must be a number').positive('Unit Price must be a positive number').required('Unit Price is required'),
    })
  ),
});

export const uploadInitialValue = {
  documents: [{
    file: "",
    description: "",
    name: "",
  }]
}

export const uploadValidationSchema = Yup.object().shape({
  documents: Yup.array().of(
    Yup.object().shape({
      file: Yup.string().trim().required('File is required'),
      description: Yup.string(),
      name: Yup.string().trim().required('Name is required'),
    })
  )
});


export const voucherInitialValue = {
  voucherNumber: "-",
  vendorType: "vendor",
  name: "",
  challanNumber: "",
  voucherType: "cash",
  expenseType: "outward",
  amount: "",
  signature: "",
  bankName: "",
  chequeNumber: "",
  chequeDate: "",
  remarks: "",
  isVisibleForOwnerOnly: false,
};



export const voucherValidationSchema = Yup.object().shape({
  vendorType: Yup.string().trim().required("Vendor type is required"),
  name: Yup.string().trim().required("Name is required"),
  challanNumber: Yup.string().trim().test('challan-number', 'Challan number is required ', function (value) {
    // if (this.parent.voucherType?.toLowerCase() !== 'cash') {
    //   return value && value.trim() !== '';
    // }
    if (this.parent.vendorType?.toLowerCase() === 'vendor') {
      return value && value.trim() !== '';
    }

    return true;
  }),
  voucherType: Yup.string().trim().required("Voucher type is required"),
  expenseType: Yup.string().trim().required("Expense type is required"),
  amount: Yup.number().required("Amount is required").typeError('Amount must be a number'),
  signature: Yup.string().trim().test('cheque-number', 'Signature is required', function (value) {
    if (this.parent.voucherType?.toLowerCase() === 'cash') {
      return value && value.trim() !== '';
    }
    return true;
  }),
  bankName: Yup.string().trim().test('bank-name', 'Bank name is required', function (value) {
    if (this.parent.voucherType?.toLowerCase() !== 'cash') {
      return value && value.trim() !== '';
    }
    return true;
  }),
  chequeNumber: Yup.string().trim().test('cheque-number', 'Cheque number is required ', function (value) {
    if (this.parent.voucherType?.toLowerCase() !== 'cash') {
      return value && value.trim() !== '';
    }
    return true;
  }),
  chequeDate: Yup.date().test('cheque-date', 'Cheque date is required ', function (value) {
    if (this.parent.voucherType?.toLowerCase() !== 'cash') {
      return value instanceof Date && !isNaN(value);
    }
    return true;
  }),
});

export const voucherFilterInitialvalues = {
  vendorType: "",
  name: "",
  challanNumber: "",
  voucherType: "",
  expenseType: "",
  dateType: "",
  to: "",
  from: ""
}

export const voucherFilterValidationSchema = Yup.object().shape({
  to: Yup.string().trim().test('to', 'Range is required ', function (value) {
    if (this.parent.dateType?.toLowerCase() === '3') {
      return value && value.trim() !== '';
    }
    return true;
  }),
  from: Yup.string().trim().test('from', 'Range is required ', function (value) {
    if (this.parent.dateType?.toLowerCase() === '3') {
      return value && value.trim() !== '';
    }
    return true;
  }),
})

export const reportFilterInitialValue = {
  reportType: "",
  vendorId: "",
  serialNumber: "",
  itemName: "",
  status: "",
  to: "",
  from: ""
}

export const reportValidationSchema = Yup.object().shape({
  to: Yup.string().trim().required('Date to is required '),
  from: Yup.string().trim().required('Date from is required '),
  reportType: Yup.string().trim().required('Report type is required'),
  serialNumber: Yup.string().trim().test('serialNumber', 'Invalid Number ', function (value) {
    if (value) {
      return validatePOChallanOrVoucherNumber.test(value);
    }
    return true;
  }),
});


export const inventoryItemInitialValue = {
  _id: "",
  isDisabled: true,
  name: "",
  localName: "",
  description: "",
  unitType: "",
  hsnCode: "",
  tax: "18",
  unitPrice: "",
  quantity: "",
  minQty:"",
  
}

export const inventoryItemValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Name is required'),
  hsnCode: Yup.string().trim().required('HSN Code is required'),
  minQty: Yup.number().min(0,"Minimum inventory quantity is 0").required('Minimum inventory quantity is required'),
});