import React, { useEffect, useState } from 'react'
import Spinwheel from '../../components/common/Spinwheel';
import toast from 'react-hot-toast';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useUserContext } from '../../context/userContext';
import { getDocumentsApi,deleteDocumentApi } from '../../utils/ApiEndpointFunctions';
import ReactPaginate from 'react-paginate';
import { debounce } from 'lodash';
import { S3_URL } from '../../utils/BaseUrl';
import ConfirmationModal from '../../components/modal/ConfirmationModal';

function Upload(props) {
  const [uploads, setUploads] = useState([])
  const [loading, setLoading] = useState(true)
  const [deleteDocument,setDeleteDocument] = useState({status:false,id:null})
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const deleteConfirmModalId = "deleteConfirmModalId"
  // let [selectedPage, setSelectedPage] = useState(1);
  const navigate = useNavigate();
  const { userDetails } = useUserContext();
  const limit = 10;
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();


  const handleChange = async (event) => {

    let search1 = event.target.value;
    setSearchParams({"page": 1})
    setSearch(search1);
    setLoading(true);
    const res = await getDocumentsApi(event.target.value, limit, 1);
    if (res.status === 200) {
      const totalData = res.data?.data?.totalData
      setTotalCount(totalData);
      setPage(Math.ceil(totalData / limit));
      setUploads(res.data?.data?.documents);

      // setSelectedPage(1);
    }
    setLoading(false);
  };

  const debouncedOnChange = debounce(handleChange, 800);

  const getUploads = async () => {
    setLoading(true);
    try {
      const page = searchParams.get("page") || 1
      const res = await getDocumentsApi(search, limit, page);
      if (res.status === 200) {

        const totalData = res.data?.data?.totalData
        setTotalCount(totalData);
        setPage(Math.ceil(totalData / limit));
        setUploads(res.data?.data?.documents);
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false);
  }


  const handlePageClick = (event) => {
    let currentPage = event.selected + 1;
    setSearchParams({"page": currentPage})
    // selectedPage = currentPage;
    // getUploads();
    // setSelectedPage(currentPage)
  };

  const showIcons = (file) => {
    if (!file) return null
    const url = S3_URL + encodeURIComponent(file)
    const type = file.split(".").pop()
    switch (type) {
      case "pdf":
        return <Link to={url} target='_blank' ><i className="fs-4 bi bi-file-earmark-pdf"></i></Link>
      case "xls":
      case "xlsx":
        return <Link to={url} target='_blank' ><i class="fs-4 bi bi-filetype-xls"></i></Link>
      case "doc":
      case "docx":
        return <Link to={url} target='_blank' ><i class="fs-4 bi bi-filetype-doc"></i></Link>
      case "csv":
        return <Link to={url} target='_blank' ><i class="fs-4 bi bi-filetype-csv"></i></Link>
      default:
        return <Link to={url} target='_blank' ><div><i className="fs-4 bi bi-image-fill"></i></div></Link>
    }
  }

  // const handleFilterUploads = () => {
  //   if (searchParams.get('page') == 1) {
  //     getUploads()
  //   } else {
  //     setSearchParams({ "page": 1 })
  //   }
  // }

  const handleDeleteDocument =async(id)=>{
    document?.getElementById(deleteConfirmModalId)?.click()
    setDeleteDocument({status:true,id:id})
    try {
      const res = await deleteDocumentApi(id)
      if(res?.status==200)
      toast.success(res?.data?.message || "Successfully document deleted")
      setDeleteDocument({status:false,id:null})
      getUploads()
    } catch (error) {
      toast.error(error?.response?.data?.message || "Failed to delete document")
      setDeleteDocument({status:false,id:null})
    }
  }

  useEffect(() => {
    getUploads()
  }, [searchParams.get('page')])
  return (
    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="main-content">
              {props.type !== "dashboard" && <div className="dash-nav1">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="mb-0 font-blue">Uploaded Document</h3>
                </div>
              </div>}
              <div className="d-flex justify-content-end align-items-center">
                {userDetails?.roleId?.permission.includes("uploadWrite") && <div><button className="create-order" onClick={() => navigate("/upload/add")}>Upload Document</button></div>}
                <div className="d-flex">
                  <nav className="navbar bg-body-tertiary">
                    <form role="search" className="container-fluid">
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon1"><i className="bi bi-search"></i></span>
                        <input type="text" className="form-control" placeholder="Search documents" aria-label="Username" aria-describedby="basic-addon1" onChange={debouncedOnChange} />
                      </div>
                    </form>
                  </nav>

                </div>
              </div>
              {/* <!-- table --> */}


              <table className="table d-table mt-4">
                <thead>
                  <tr>
                    <th scope="col-2">NAME</th>
                    <th scope="col-8">DESCRIPTION</th>
                    {userDetails?.roleId?.name?.toLowerCase()!="staff" && <th scope="col-1">Uploaded by</th>} 
                    <th scope="col-1">DOCUMENT</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {
                  loading ?
                    <tbody >
                      <tr>
                        <td colSpan={12}>
                          <div className="d-flex justify-content-center">
                            <Spinwheel />
                          </div>
                        </td>
                      </tr>
                    </tbody> :
                    <>
                      <tbody>

                        {uploads?.length > 0 ? uploads?.map((ele, index) => {
                          return (
                            <tr key={index}>
                              <td>{ele?.name}</td>
                              <td>{ele?.description}</td>
                              {userDetails?.roleId?.name?.toLowerCase()!="staff" && <td className='text-capitalize'>{ele?.user?._id==userDetails?._id ? "Self" : `${ele?.user?.firstName} ${ele?.user?.lastName}`}</td>} 
                              <td>{showIcons(ele?.file)}</td>
                              <td><button id="deleteModalBtn1" data-bs-toggle="modal" data-bs-target={`#${deleteConfirmModalId}`} onClick={()=>setDeleteDocument({...deleteDocument,id:ele?._id})} className='create-order' disabled={deleteDocument?.status}> {deleteDocument?.status && deleteDocument?.id==ele?._id ? <Spinwheel sizeClass={"spin-small"} /> : "Delete"}</button></td>
                            </tr>)
                        }) : <tr className='text-center'>
                          <td colSpan="12">
                            <h1>Data Not Found</h1>
                          </td>
                        </tr>}
                      </tbody></>}
              </table>

            </div>
          </div>

          {totalCount > limit ? (
            <div className="d-flex justify-content-center">
              <ReactPaginate
                nextLabel="Next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                pageCount={page}
                previousLabel="< Previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName={"active"}
                forcePage={searchParams.get('page')? Number(searchParams.get('page'))-1 : 0}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <ConfirmationModal modalId={deleteConfirmModalId} handleConfirm={() => deleteDocument?.id && handleDeleteDocument(deleteDocument?.id)} message={<>Are you sure you want to <br /> Delete ?</>} />
    </>
  )
}

export default Upload